import(/* webpackMode: "eager" */ "/app/components/CustomCarouselSlick/index.tsx");
import(/* webpackMode: "eager" */ "/app/components/Slide/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/Breadcrumbs/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/home/components/Banner.module.scss");
import(/* webpackMode: "eager" */ "/app/components/basic/Image/styles.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/home/components/CardProduct/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/MoreProductsList/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideProducts.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideSales.tsx");
import(/* webpackMode: "eager" */ "/app/modules/home/pages/index.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/home/components/SlideProducts.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/LeftsideCategories/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/layout/components/TopCategoriesLayout/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/ButtonCategoryShowMore/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/ButtonShowMore/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/CommonPageProductsList.module.scss");
import(/* webpackMode: "eager" */ "/app/modules/public/components/CommonPageSidebar.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/HeaderPageCategory/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/LazyProductsList/index.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/components/PageProductsListContext.tsx");
import(/* webpackMode: "eager" */ "/app/modules/public/hooks/useData.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-loading-skeleton/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-loading-skeleton/dist/skeleton.css")